import axios from 'axios';
import { getApiUrl } from './url';

export type EventItem = {
  title: string;
  start: Date;
  end: Date;
  description?: string;
  type?: string;
  id?: string;
};

export const getEvents = async () => {
  const resp = await axios.get(`${getApiUrl()}/events`);
  return resp.data;
};
