import React, { useState, useEffect } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ListSubheader from '@mui/material/ListSubheader';
import Grid from '@mui/material/Unstable_Grid2';
import MemberShipForm from './memberRegistration';
import { getMembers, Member } from '../../api/member';
import {AppContext} from '../../App'

export default function MemberList() {

  const [members, setMembers] = useState<Member[]>([]);
  const [loading, setLoading] = useState(true);
  const loadEvents = () => {
    getMembers().then((result) => {
      setMembers(result.data);
      setLoading(false);
    });
  };

  useEffect(() => loadEvents(), []);
  const imagePath = React.useContext(AppContext).imagePath;
  return (
    <>
      {!loading && (
        <List
          sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
          subheader={
            <ListSubheader>
              <Grid container spacing={1}>
                <Grid xs={6}>
                  <Typography
                    variant="body2"
                    style={{ fontSize: 20, color: 'darkorange' }}
                  >
                    Members
                  </Typography>
                </Grid>
                <Grid xs={6}>
                  <MemberShipForm></MemberShipForm>
                </Grid>
              </Grid>
            </ListSubheader>
          }
        >
          <>
            {members.map((m) => {
              return (
                <ListItem alignItems="flex-start" key={m._id}>
                  <ListItemAvatar>
                    <Button>
                      <Avatar
                        alt={m.first_name}
                        src={`${imagePath}/${m._id}.${m.imageType}`}
                      />
                    </Button>
                  </ListItemAvatar>
                  <ListItemText
                    primary={m.role}
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: 'inline' }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {`${m.first_name} ${m.last_name}`}
                        </Typography>
                        {m.about_yourself && (
                          <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            {` — ${m.about_yourself}…`}
                          </Typography>
                        )}
                      </React.Fragment>
                    }
                  />
                  <Divider></Divider>
                </ListItem>
              );
            })}
          </>
        </List>
      )}
      {loading && <p>Loading</p>}
    </>
  );
}
