import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { getEvents, EventItem } from '../../api/events';

const localizer = momentLocalizer(moment);
export const EventCalendar = () => {
  const [events, setEvents] = useState<EventItem[]>([]);
  const [loading, setLoading] = useState(true);
  const loadEvents = () => {
    getEvents().then((result) => {
      const _data = result.data;
      setEvents(
        _data.map((i: EventItem) => {
          return {
            title: i.title,
            start: new Date(i.start),
            end: new Date(i.end),
          };
        }),
      );
      setLoading(false);
    });
  };

  useEffect(() => loadEvents(), []);
  return (
    <>
      {!loading && (
        <Calendar
          localizer={localizer}
          events={events}
          style={{ height: 200 }}
        />
      )}
      {loading && <p>Loading</p>}
    </>
  );
};
