/* eslint-disable react/display-name */
import React, { useState, useImperativeHandle, forwardRef } from 'react';
import UploadService from '../../api/upload';
import Button from '@mui/material/Button';

export interface IFile {
  url: string;
  name: string;
}

type FileProps = {
  sendData: (fileInfo: { ext: string }) => void;
};

const ImageUpload = forwardRef((props: FileProps, ref) => {
  const [currentImage, setCurrentImage] = useState<ArrayBuffer>();
  const [previewImage, setPreviewImage] = useState<string>('');
  const [progress, setProgress] = useState<number>(0);
  const [message, setMessage] = useState<string>('');
  const [ext, setExt] = useState('');
  const selectImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    const reader = new FileReader();
    reader.onload = () => {
      setCurrentImage(reader.result as ArrayBuffer);
    };
    if (selectedFile) {
      setExt(selectedFile.name.split('.').pop() || 'unknown');
      reader.readAsArrayBuffer(selectedFile);

      setPreviewImage(URL.createObjectURL(selectedFile));
    }
    setProgress(0);
  };

  useImperativeHandle(ref, () => ({
    upload(fileId: string) {
      setProgress(0);
      if (!currentImage) return;
      props.sendData({ ext });
      console.log(currentImage);
      UploadService.uploadToS3({
        fileType: ext,
        fileContents: currentImage,
        fileId,
      }).catch((err) => {
        setProgress(0);

        if (err.response && err.response.data && err.response.data.message) {
          setMessage(err.response.data.message);
        } else {
          setMessage('Could not upload the Image!');
        }
      });
    },
  }));

  return (
    <div>
      <div className="row">
        <div className="col-8">
          <input
            style={{ display: 'none' }}
            id="contained-button-file"
            type="file"
            accept="image/*"
            onChange={selectImage}
          />
          <label htmlFor="contained-button-file">
            <Button variant="contained" color="primary" component="span">
              Add Profic Pic
            </Button>
          </label>
        </div>
      </div>

      {currentImage && progress > 0 && (
        <div className="progress my-3">
          <div
            className="progress-bar progress-bar-info"
            role="progressbar"
            aria-valuenow={progress}
            aria-valuemin={0}
            aria-valuemax={100}
            style={{ width: progress + '%' }}
          >
            {progress}%
          </div>
        </div>
      )}

      {previewImage && (
        <div>
          <img className="preview" src={previewImage} alt="" />
        </div>
      )}

      {message && (
        <div className="alert alert-secondary mt-3" role="alert">
          {message}
        </div>
      )}
    </div>
  );
});
export default ImageUpload;
