import axios from 'axios';
import { getApiUrl } from './url';

export type Media = {
  title: string;
  description: string;
  date: Date;
  image: string;
};

export const getMedia = async () => {
  const resp = await axios.get(`${getApiUrl()}/media`);
  return resp.data;
};
