import React, { useState, useEffect } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Box from '@mui/material/Box';
import {AppContext} from '../../App'
import '../../App.css'
import { getMedia, Media } from '../../api/media';


export default function Carousal() {
  const [media, setMedia] = useState<Media[]>([]);
  const [loading, setLoading] = useState(true);
  const loadMedia = () => {
    getMedia().then((result: {data:[]}) => {
      setMedia(result.data);
      setLoading(false);
    });
  };
  
  useEffect(() => loadMedia(), []);
  const imagePath = React.useContext(AppContext).imagePath
  return (
    <>
      {!loading && (
          <Carousel>
            {media.map((m) => {
              return (
                <>
                  <div className = 'carouselImage'>
                      <Box
                        component="img"
                        alt= {m.title}
                        src={`${imagePath}/media/${m.image}`}
                      />
                      <p>{m.title}</p>
                    </div>
              </>
              )
          }
        )
      }
    </Carousel>)}
    {loading && <p>Loading</p>}
    </>
  );
}
