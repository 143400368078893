import * as React from 'react';
import './App.css';
import { EventCalendar } from './components/calendar/event';
import SimpleBottomNavigation from './components/layout/bottomNav';
import ResponsiveAppBar from './components/layout/appBar';
import MemberList from './components/members/members';
import LeaderBoard from './components/members/leaderBoard';
import Carousal from './components/articles/media';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import {getImageUrl} from './api/url'

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export const AppContext = React.createContext({imagePath: ''});

function App() {
  const [imagePath, setImagePath] = React.useState<string>('');
  React.useEffect(() => setImagePath(getImageUrl()), []);
  return (
    <AppContext.Provider value={{ imagePath }}>
    <div style={{ width: '100%', padding: 5 }}>
      <div style={{ padding: 5 }}>
        <ResponsiveAppBar></ResponsiveAppBar>
      </div>
      <div style={{ padding: 3 }}>
        <Grid container spacing={2}>
          <Grid xs={8} md={4}>
            <Item>
              <div className="medium-text">
                <p>
                  The first general election debate between the major candidates
                  of the 2024 United States presidential election was sponsored
                  by CNN and attended by presumptive Democratic nominee Joe
                  Biden and presumptive Republican nominee Donald Trump on June
                  27, 2024. Biden withdrew from the race in July, and was
                  replaced by Kamala Harris, while Trump became the official
                  Republican nominee that same month. The second debate, which
                  will be sponsored by ABC, is scheduled to be held on September
                  10, 2024.
                </p>
                <p>
                  Four general election debates sponsored by the Commission on
                  Presidential Debates (CPD) were originally scheduled to be
                  held between September 16 and October 9, 2024. Both Biden and
                  Trump were against the CPDs debate format and schedule. In May
                  2024, both campaigns agreed to bypass the CPD and hold the
                  alternative debates, canceling the CPD debates.
                </p>
                <p>
                  Biden performed poorly during the first debate, with many
                  commentators and Democrats calling for him to drop out of the
                  race; the debate performance ultimately led to Biden
                  withdrawing his bid for re-election on July 21. His withdrawal
                  led to doubts over the September 10 debate; Trump suggested in
                  late July on Truth Social that the September 10 ABC debate
                  should instead be held by Fox News.
                </p>
              </div>
            </Item>
            <br></br>
            <Item>
              <div className="medium-text" style={{ padding: 2 }}>
                <p>
                  The first general election debate between the major candidates
                  of the 2024 United States presidential election was sponsored
                  by CNN and attended by presumptive Democratic nominee Joe
                  Biden and presumptive Republican nominee Donald Trump on June
                  27, 2024. Biden withdrew from the race in July, and was
                  replaced by Kamala Harris, while Trump became the official
                  Republican nominee that same month. The second debate, which
                  will be sponsored by ABC, is scheduled to be held on September
                  10, 2024.
                </p>
                <p>
                  Four general election debates sponsored by the Commission on
                  Presidential Debates (CPD) were originally scheduled to be
                  held between September 16 and October 9, 2024. Both Biden and
                  Trump were against the CPDs debate format and schedule. In May
                  2024, both campaigns agreed to bypass the CPD and hold the
                  alternative debates, canceling the CPD debates.
                </p>
                <p>
                  Biden performed poorly during the first debate, with many
                  commentators and Democrats calling for him to drop out of the
                  race; the debate performance ultimately led to Biden
                  withdrawing his bid for re-election on July 21. His withdrawal
                  led to doubts over the September 10 debate; Trump suggested in
                  late July on Truth Social that the September 10 ABC debate
                  should instead be held by Fox News.
                </p>
              </div>
            </Item>
          </Grid>
          <Grid xs={10} md={4}>
            <Item>
              <MemberList></MemberList>
            </Item>
            <br />
            <Item>
              <Carousal></Carousal>
            </Item>
          </Grid>
          <Grid xs={10} md={4}>
            <Item>
              <LeaderBoard></LeaderBoard>
            </Item>
          </Grid>
          <Grid xs={10} md={6}>
            <Item>
              <div>
                <EventCalendar></EventCalendar>
              </div>
            </Item>
          </Grid>
        </Grid>
      </div>
      <div>
        <SimpleBottomNavigation></SimpleBottomNavigation>
      </div>
    </div>
    </AppContext.Provider>
  );
}

export default App;
