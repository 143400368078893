import axios from 'axios';
import { getApiUrl } from './url';

export type Member = {
  first_name: string;
  middle_name?: string;
  last_name: string;
  email: string;
  about_yourself?: string;
  imageType?: string;
  role?: string;
  _id: string;
};

export const saveMember = async (member: Member) => {
  const resp = await axios.post(`${getApiUrl()}/members`, member);
  return resp.data;
};

export const getMembers = async () => {
  const resp = await axios.get(`${getApiUrl()}/members`);
  return resp.data;
};
