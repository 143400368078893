import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ListSubheader from '@mui/material/ListSubheader';
import {AppContext} from '../../App'

export default function LeaderBoard() {
  const imagePath = React.useContext(AppContext).imagePath
  return (
    <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      subheader={<ListSubheader>Leader Board</ListSubheader>}
    >
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Button>
            <Avatar alt="Sharad" src={`${imagePath}/sheru.jpg`} />
          </Button>
        </ListItemAvatar>
        <ListItemText
          primary="Host"
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                Sharad
              </Typography>
              {' — I love to talk…'}
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Button>
            <Avatar alt="Shraddha" src={`${imagePath}/shraddha.png`} />
          </Button>
        </ListItemAvatar>
        <ListItemText
          primary="Co-host"
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                Shraddha
              </Typography>
              {' — I love to listen…'}
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Button>
            <Avatar alt="Sanjay" src={`${imagePath}/dad01.jpg`} />
          </Button>
        </ListItemAvatar>
        <ListItemText
          primary="Advisor"
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                Sanjay
              </Typography>
              {' — I love to help…'}
            </React.Fragment>
          }
        />
      </ListItem>
    </List>
  );
}
