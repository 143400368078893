import axios from 'axios';
import { getApiUrl } from './url';
import * as dictionary from '../helpers/dictionary';

type S3Upload = {
  fileType: string;
  fileContents: ArrayBuffer;
  fileId: string;
};

type PresignedUrl = {
  fileType: string;
  key: string;
};

const uploadToS3 = async ({ fileType, fileContents, fileId }: S3Upload) => {
  const cType = fileType ? dictionary.getContentType(fileType) : '';
  console.log({
    fileType,
    key: `assets/imgs/${fileId}.${fileType}`,
    headers: { 'Content-Type': cType },
  });
  const presignedPutUrl = await getPresignedPutUrl({
    fileType,
    key: `assets/imgs/${fileId}.${fileType}`,
  });

  const response = await axios.put(presignedPutUrl.data, fileContents, {
    headers: {
      'Content-Type': cType,
    },
  });

  return { ...response, fileType };
};

async function getPresignedPutUrl(event: PresignedUrl) {
  const presignedPutUrl = await axios.post(`${getApiUrl()}/images`, event);
  console.log(presignedPutUrl.data);
  return presignedPutUrl.data as unknown as { data: string };
}

const FileUploadService = {
  uploadToS3,
};

export default FileUploadService;
