import React, { useRef } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Icon from '@mui/material/Icon';
import { saveMember, Member } from '../../api/member';
import ImageUpload from '../common/fileuploader';

export default function MemberShipForm() {
  const [open, setOpen] = React.useState(false);
  const [fileInfo, setFileInfo] = React.useState({ ext: '' });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ref = useRef<any>(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getData = (info: { ext: string }) => {
    setFileInfo(info);
    console.log(fileInfo);
  };

  return (
    <React.Fragment>
      <Button
        key="member-registration"
        onClick={handleClickOpen}
        sx={{
          color: 'black',
          display: 'flex',
          textTransform: 'none',
        }}
      >
        <Icon style={{ color: 'darkorange', paddingRight: 10 }}>
          person_add_rounder_alt
        </Icon>
        New member
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          encType: 'multipart/form-data',
          onSubmit: async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = {
              ...Object.fromEntries(formData.entries()),
              imageType: fileInfo.ext,
            };
            console.log(formJson);
            const result = await saveMember(formJson as Member);
            if (ref && ref.current) {
              await ref.current.upload(result.data);
            }
          },
          style: {
            backgroundColor: '#f0f0f0',
            color: 'black',
          },
        }}
        style={{ fontSize: 10 }}
      >
        <DialogTitle>Become a member</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please provide necessary information
          </DialogContentText>

          <TextField
            autoFocus
            required
            margin="dense"
            id="first_name"
            name="first_name"
            label="First Name"
            type="text"
            fullWidth
            variant="outlined"
          />
          <TextField
            margin="dense"
            id="middle_name"
            name="middle_name"
            label="Middle Name"
            type="text"
            fullWidth
            variant="outlined"
          />
          <TextField
            required
            margin="dense"
            id="last_name"
            name="last_name"
            label="Last Name"
            type="text"
            fullWidth
            variant="outlined"
          />
          <TextField
            required
            margin="dense"
            id="name"
            name="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="outlined"
          />
          <TextField
            margin="dense"
            id="about_yourself"
            name="about_yourself"
            label="Tell us about yourself"
            type="text"
            fullWidth
            variant="outlined"
          />
          <ImageUpload ref={ref} sendData={getData}></ImageUpload>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Submit</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
